import './App.css';
import { Fragment } from 'react';
import Home from './pages';

function App() {
  return (
    <Fragment>
      <Home />
    </Fragment>
  );
};

export default App;
